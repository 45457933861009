<template>
  <div>
    <section class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <b-container fluid>
        <b-row>
          <b-col md="12" lg="12" xl="6">
            <div class="wrapper">
              <div class="title">
                <span>KAPMOBILE.ID</span>
              </div>

              <div class="sub">
                <span>Masukkan email anda untuk mengubah password</span>
              </div>

              <div class="form">
                <b-form-input
                  v-model="email"
                  placeholder="Email"
                  class="inputs"
                  type="email"
                ></b-form-input>

                <div class="button-wrapper">
                  <b-button  @click="resetPass">
                    <span> Reset Password</span>
                    
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>

          <b-col xl="6" class="d-md-none d-lg-none d-xl-block pr-0">
            <div class="wrapper2">
              <!-- <div class="ads">iklan</div>
              <div class="ads">iklan</div>
              <div class="ads">iklan</div> -->
            </div>
          </b-col>
        </b-row>

        <!-- for ads -->
        <b-row class="d-xl-none mt-5">
          <b-col md="10" lg="10" offset-md="1" offset-lg="1"> </b-col>
        </b-row>
        <!-- end ads -->
      </b-container>
    </section>

    <section class="d-sm-block d-md-none d-lg-none d-xl-none">
      <b-container>
        <b-row>
          <b-col cols="12" sm="12">
            <div
              style="
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <h4 class="text-center">
                <strong>Does not support mobile version</strong>
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import whiteSpinner from "../../components/spinner/white-spinner.vue";
export default {
  components: { whiteSpinner },
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  methods: {
    resetPass() {
      let vm = this;
      vm.loading = true;
      axios
        .post("resetPassword/reset", {
          email: vm.email,
        })
        .then((res) => {
          if (res.data.message) {
            vm.loading = false;
            vm.$router.push("/submit_password/" + vm.email);
          }
        })
        .catch((err) => {
          vm.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white2": #fff,
);

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 25%;
  .title {
    font-family: "nunito", sans-serif;
    font-weight: 700;
    font-size: 48px;
    margin-top: 8%;
    margin-bottom: 2%;
    color: map-get($color, "blue");
  }
  .sub {
    font-family: "nunito", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 2%;
  }
  .form {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .inputs {
      height: 57px;
      font-family: "nunito", sans-serif;
      font-weight: 700;
      font-size: 16px;
      margin: 2% 0;
      border-radius: 6px;
      margin-bottom: 6%;
    }
    .button-wrapper {
      .btn {
        width: 100%;
        height: 57px;
        border-radius: 6px;
        font-family: "nunito", sans-serif;
        text-transform: capitalize;
        font-weight: 700;
        background-color: map-get($color, "blue");
        border: none;
      }
    }
  }
}

.wrapper2 {
  background-image: url("../../assets/vector/Background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: #327ff2;
  // background-blend-mode: soft-light;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .ads {
    width: 80%;
    height: 25%;
    border-radius: 10px;
    background-color: map-get($color, "white2");
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
